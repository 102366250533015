export enum QueryKey {
    GET_USER = 'getUser',
    GET_QR_CODE = 'getQrCode',
    GET_SMART_BOOK = 'getSmartBook',
    GET_SMART_OBJECT = 'getSmartObject',
    GET_USER_BOOK = 'getUserBook',
    GET_USER_STATS = 'getUserStats',
    GET_USER_BOOKS_LIBRARY = 'getUserBooksLibrary',
    GET_USER_AUDIOS_LIBRARY = 'getUserAudiosLibrary',
    GET_RELATED_BOOKS = 'getRelatedBooks',
    GET_REC_CODE = 'getRecCode',
}
