import React, { FC, useContext } from "react";
import styles from "./styles.module.scss";
import { flags } from "../../../../../config/flags";
import { getSizedImage, ImageSizesValues } from "../../../../../utils/getSizedImage";
import { getNarratorName } from "../../../../../utils/langUtils";
import cn from "classnames";
import { formatTime } from "../../../../../hooks/useTimer";
import Curtain from "../../../Curtain";
import { useTranslation } from "react-i18next";
import { IRecord } from "../../../../../types/IRecord";
import { LangContext } from "../../../../../context/lang";

type Props = {
    open: boolean;
    selectedNarrator: IRecord;
    narratorList: IRecord[];
    bookNarratorsLength: number;
    toggleShowNarrators: () => void;
    handleSelectNarrator: (id: string) => () => void;
}
const Narrators: FC<Props> = ({ open, toggleShowNarrators, bookNarratorsLength, narratorList, selectedNarrator, handleSelectNarrator }) => {
    const { t } = useTranslation('common');
    const { lang } = useContext(LangContext);
    return (
        <Curtain open={open} onClose={toggleShowNarrators}>
            <div className={styles.narrators}>
                <h6>{t('chooseNarrator')}</h6>
                {narratorList.map((record, index) => {
                    const { narratorAvatar, id, lang: narLAng, pages } = record;
                    const duration =
                        pages.reduce((acc: number, { duration, clientDuration }) => {
                            acc += duration || clientDuration || 0; // milliseconds
                            return acc;
                        }, 0) || 0;
                    const flagImg = flags[lang];
                    const isActive = selectedNarrator.id === id;
                    const sizedAvatar = getSizedImage(narratorAvatar.src, ImageSizesValues['140x140']);
                    const name = getNarratorName({ record, appLang: lang, fallbackLang: narLAng });
                    const contributionType = index < bookNarratorsLength ? t('audioBook') : record.familyRelationship || t('familyFriends');
                    return (
                        <div
                            key={id}
                            className={cn(styles.narratorItem, { [styles.active]: isActive })}
                            onClick={handleSelectNarrator(id)}
                        >
                            <picture className={styles.avatar}>
                                {sizedAvatar?.webp && <source srcSet={sizedAvatar.webp} type='image/webp' />}
                                <img
                                    src={sizedAvatar?.jpg || sizedAvatar?.original || narratorAvatar.src}
                                    alt={name}
                                    loading='lazy'
                                />
                            </picture>
                            <div className={styles.info}>
                                <span>{name}</span>
                                <p>{contributionType}</p>
                            </div>
                            {flagImg && (
                                <picture className={styles.lang}>
                                    <img src={flags[narLAng]} alt={narLAng} loading='lazy' />
                                </picture>
                            )}
                            {duration && <div className={styles.duration}>{formatTime(duration)}</div>}
                        </div>
                    );
                })}
            </div>
        </Curtain>
    )
}
export default Narrators
