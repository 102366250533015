import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Lang, langMap, Markets, shippingZones } from "../config/lang";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "./auth";

interface IContextData {
    lang: Lang;
    market: Markets;
    setMarket: (market: Markets) => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = { lang: Lang.en, market: Markets.de, setMarket: () => Markets.de };

export const LangContext = createContext(initData);

const LangContextProvider: FC<IContextProviderProps> = ({ children }) => {
    const { i18n } = useTranslation();
    const { user } = useContext(AuthContext);

    const [lang, setLang] = useState<Lang>(i18n?.resolvedLanguage as Lang);
    const [market, setMarket] = useState<Markets>(Markets.de);

    useEffect(() => {
        // default shipping market DE
        let shippingMarket = Markets.de;
        // getting user time zone
        const zone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
        if (zone) {
            // getting shipping market by time zone
            shippingMarket = shippingZones[zone as keyof typeof shippingZones] || shippingMarket;
        }
        // getting shipping market by user saved prop if exists
        shippingMarket = user?.app_shipping_country || shippingMarket;

        // getting browser lang
        let appLang = i18n?.resolvedLanguage as Lang;
        // getting list of supported langs for shipping market
        const langs = langMap[shippingMarket];
        // checking if browser lang is supported for shipping market if not setting first supported lang as default
        appLang = langs.includes(appLang) ? appLang : langs[0];

        setMarket(shippingMarket);
        setLang(appLang)

    }, [user, i18n]);

    useEffect(() => {
        i18n.on('languageChanged', lng => {
            setLang(lng as Lang);
        });
    }, [i18n]);

    return (
        <LangContext.Provider
            value={useMemo(
                () => ({
                    lang,
                    market,
                    setMarket,
                }),
                [lang, market, setMarket],
            )}
        >
            {children}
        </LangContext.Provider>
    );
};

export default LangContextProvider;
