import { doc, DocumentData, getDoc, updateDoc } from "firebase/firestore/lite";
import { ISmartObject } from "../types/ISmartObject";
import { db } from "../firebase";
import { FirebaseCollection } from "../utils/firebase";
import { IRecord } from "../types/IRecord";
import { requestRecords } from "./record";

export const getSmartObjectQueryKey = 'smartObject';
export const getSmartObjectRecordsQueryKey = 'smartObjectRecords';

export const hydrateSmartObject = (id: string, data: DocumentData): ISmartObject => {
    const initData: ISmartObject = {
        ownerAvatar: {
            src: '',
        },
        recordingsList: [],
        smartObjectBook: '',
        type: 'DIGITAL BOOK',
        id: '',
    };

    return {
        ...initData,
        ...data,
        id,
    };
};

export const requestSmartObject = async (id?: string): Promise<ISmartObject | null> => {
    if (!id) return null;
    const docRef = doc(db, FirebaseCollection.SMART_OBJECT, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (!data) {
        return null;
    }
    return hydrateSmartObject(docRef.id, data);
};

export const requestSmartObjectRecords = async (smartObjectId?: string): Promise<IRecord[]> => {
    if (!smartObjectId) return [];
    const smartObject = await requestSmartObject(smartObjectId);
    let records: IRecord[] = [];

    if (smartObject) {
        records = await requestRecords(smartObject.recordingsList);
    }
    return records;
}

export type ReqUpdateSmartObject = {
    id: string, data: Partial<ISmartObject>
}
export const requestUpdateSmartObject = async ({ id, data }: ReqUpdateSmartObject): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.SMART_OBJECT, id);
    await updateDoc(recordRef, data);
};
