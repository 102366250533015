import { Lang } from "../config/lang";
import { Author, BookDescription, BookImage } from "../types/IBook";
import { IRecord } from "../types/IRecord";

type GetAuthorNameProps = {
    author: Author | null | undefined;
    appLang: Lang;
    fallbackLang?: Lang;
};
export const getAuthorName = ({ author, fallbackLang, appLang }: GetAuthorNameProps): string => {
    if (!author || !appLang) return '';
    let authorName = author?.names?.find(({ lang: authorLang }) => authorLang?.toLowerCase() === appLang?.toLowerCase());
    if (fallbackLang) {
        authorName = authorName || author?.names?.find(({ lang: authorLang }) => authorLang?.toLowerCase() === fallbackLang?.toLowerCase());
    }
    return authorName?.name || author?.names?.[0]?.name || `${author?.firstName} ${author?.lastName}`.trim() || '';
}

type GetAuthorNamesProps = {
    authors: Author[] | null | undefined;
    appLang: Lang;
    fallbackLang?: Lang;
};

export const getAuthorsNames = ({ authors, fallbackLang, appLang }: GetAuthorNamesProps): string[] => {
    if (!authors || !appLang) return [];
    const names =  authors?.map(author => {
        const name = author.names.find(({ lang: authorLang }) => authorLang?.toLowerCase() === appLang?.toLowerCase())?.name;
        const fallbackName = fallbackLang ? author?.names?.find(({ lang: authorLang }) => authorLang?.toLowerCase() === fallbackLang?.toLowerCase())?.name : null;
        return name || fallbackName || author?.names?.[0]?.name || `${author?.firstName} ${author?.lastName}`.trim() || null;
    });

    return names.filter(name => name) as string[];
}

type GetImageByLangProps = {
    images: BookImage[];
    appLang: Lang;
    fallbackLang?: Lang;
};
export const getImageByLang = ({ images, fallbackLang, appLang }: GetImageByLangProps): string | null => {
    if (!images) return null;

    let image = images.find(({ lang: imgLang }) => imgLang.toLowerCase() === appLang.toLowerCase());
    if (fallbackLang) {
        image = image || images.find(({ lang: imgLang }) => imgLang.toLowerCase() === fallbackLang.toLowerCase());
    }
    return image?.img?.src || images?.[0]?.img?.src || null;
    // const image = images.find(({ lang }) => lang.toLowerCase() === language.toLowerCase());
    // return image?.img?.src || images?.[0]?.img?.src || null;
}

type GetNarratorNameProps = {
    record: IRecord | null | undefined;
    appLang: Lang;
    fallbackLang?: Lang;
};
export const getNarratorName = ({ record, fallbackLang, appLang }: GetNarratorNameProps): string => {
    if (!record) return '';

    let recName = record?.contributor?.names?.find(({ lang: recLang }) => recLang?.toLowerCase() === appLang.toLowerCase());
    // recName = recName || record?.narratorName;

    if (fallbackLang) {
        recName = recName || record?.contributor?.names?.find(({ lang: recLang }) => recLang?.toLowerCase() === fallbackLang.toLowerCase());
    }
    return recName?.name || record?.narratorName || '';
}

type GetDescriptionProps = {
    texts: BookDescription[] | null | undefined;
    appLang: Lang;
    fallbackLang?: Lang;
};

export const getTextByLang = ({ texts, fallbackLang, appLang }: GetDescriptionProps): string | null => {
    if (!texts) return null;
    let text = texts.find(({ lang }) => lang.toLowerCase() === appLang.toLowerCase());

    if (fallbackLang) {
        text = text || texts.find(({ lang }) => lang.toLowerCase() === fallbackLang.toLowerCase());
    }
    return text?.text || texts?.[0]?.text || null;
}
