import { useMutation } from 'react-query';
import qrCodeStorage from '../storage/qr-code';
import { useQrCode } from '../queries/useQrCode';

const removeQrCode = async () => {
    qrCodeStorage.remove();
};

export const useRemoveQrCode = () => {
    const { refetch } = useQrCode();

    return useMutation({
        mutationFn: () => removeQrCode(),
        onSuccess: () => refetch(),
    });
};
