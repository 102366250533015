import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { IRecord, ProcessingStatus } from '../../../types/IRecord';
import Narrator from './components/Narrator';
import BackCircleArrow from '../../icons/BackCircleArrow';
import PauseIcon from '../../icons/Pause';
import PlayIcon from '../../icons/Play';
import ForwardCircleArrowIcon from '../../icons/ForwardCircleArrow';
import AudioProgressBar from './components/AudioProgressBar';
import { LangContext } from '../../../context/lang';
import RecordButton from '../RecordButton';
import AddToLibrary from '../../../pages/Book/components/AddToLibrary';
import { BookFormat, Chapter, LangName } from '../../../types/IBook';
import { AuthContext } from '../../../context/auth';
import qrCodeStorage from '../../../storage/qr-code';
import Button from '../Button';
import BellIcon from '../../icons/Bell';
import CrossedBell from '../../icons/CrossedBell';
import ListIcon from '../../icons/List';
import { Lang } from '../../../config/lang';
import PlayPrevIcon from '../../icons/PlayPrev';
import PlayNext from '../../icons/PlayNext';
import LoadingIcon from '../../icons/Loading';
import { getSizedImage, ImageSizesValues } from '../../../utils/getSizedImage';
import { getNarratorName } from '../../../utils/langUtils';
import { toast } from 'react-toastify';
import { getCDNUrl } from '../../../utils/cdnLinks';
import { useTranslation } from 'react-i18next';
import MiniPlayer from './components/MiniPlayer';
import Narrators from './components/Narrators';
import Chapters from './components/Chapters';
import EqualizerIcon from '../../icons/Equalizer';

type Props = {
    bookId?: string; // needed to show record button
    bookLangs?: Lang[];
    cover: string;
    title: string;
    author?: string | null;
    narrators: IRecord[];
    bookNarrators: IRecord[];
    selectedNarrator: IRecord | null;
    chapters?: Chapter[]; // sorted by chapterId
    isSample: boolean;
    showMiniPlayer?: boolean;
    bookAdditionalFormats?: BookFormat[];
    bookByeLink?: string;
    onNarratorChange?: (id: string) => void;
    toggleMiniPlayerMode?: () => void;
    onMiniPlayerClose?: () => void;
};

let bellChangeAudioCurrentTime = 0;
const Player: FC<Props> = ({
    bookId,
    cover,
    title,
    author,
    selectedNarrator,
    isSample,
    bookNarrators,
    narrators,
    showMiniPlayer,
    onNarratorChange,
    bookAdditionalFormats,
    bookByeLink,
    chapters,
    bookLangs,
    toggleMiniPlayerMode,
    onMiniPlayerClose,
}) => {
    const [selectedTrackIdx, setSelectedTrackIdx] = useState<number>(0);
    const [selectedChapter, setSelectedChapter] = useState<Chapter | undefined>(chapters?.[selectedTrackIdx]);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [showNarrators, setShowNarrators] = useState<boolean>(false);
    const [bellIsOn, setBellIsOn] = useState<boolean>(false);
    const [openChapters, setOpenChapters] = useState<boolean>(false);
    const [isAudioLoading, setIsAudioLoading] = useState<boolean>(false);

    const { lang } = useContext(LangContext);

    const { t } = useTranslation('common');

    const { user } = useContext(AuthContext);

    const audioRef = useRef<HTMLAudioElement>(null);

    const hasChapters = useMemo(() => {
        return !!(chapters && chapters.length > 0 && selectedNarrator?.chapters && selectedNarrator.chapters.length > 0);
    }, [chapters, selectedNarrator]);

    const selectedAudioTrack = useMemo(() => {
        if (hasChapters) {
            const selectedChapterId = selectedChapter?.chapterId || 0;
            const chapter = selectedNarrator?.chapters?.find(({ chapterId }) => chapterId.toString() === selectedChapterId.toString());
            const src = bellIsOn ? chapter?.full_transition_audio?.src || undefined : chapter?.full_audio?.src || undefined;
            if (!src) {
                toast.error(t('player.noAudio'));
            }
            return getCDNUrl(src);
        }
        const defSrc = bellIsOn
            ? selectedNarrator?.full_transition_audio?.src || undefined
            : selectedNarrator?.full_audio?.src || undefined;
        return getCDNUrl(defSrc);
    }, [selectedNarrator, hasChapters, bellIsOn, selectedChapter, t]);

    const handlePlayPress = useCallback(() => {
        audioRef.current?.play();
        // setIsPlaying(true);
    }, []);

    const handlePausePress = useCallback(() => {
        audioRef.current?.pause();
        // setIsPlaying(false);
    }, []);

    const handleSecBackPress = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.currentTime = audioRef.current.currentTime - 10;
        }
    }, []);

    const handleSecNextPress = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.currentTime = audioRef.current.currentTime + 10;
        }
    }, []);

    let fullDuration: number = useMemo(() => {
        if (selectedChapter && selectedNarrator?.chapters && selectedNarrator.chapters.length > 0) {
            return (
                selectedNarrator.chapters.find(({ chapterId }) => chapterId.toString() === selectedChapter.chapterId.toString())
                    ?.duration_ms || 0
            );
        }
        // if (chapters && chapters.length > 0 && selectedNarrator?.chapters && selectedNarrator.chapters.length > 0) {
        //     return selectedNarrator.chapters.reduce((acc: number, { durationMs }) => {
        //         acc += durationMs || 0; // milliseconds
        //         return acc;
        //     }, 0);
        // }
        return (
            selectedNarrator?.pages.reduce((acc: number, { duration, clientDuration }) => {
                acc += duration || clientDuration || 0; // milliseconds
                return acc;
            }, 0) || 0
        );
    }, [selectedNarrator, selectedChapter]);

    const narratorList = useMemo(() => {
        const doneNarrators =
            narrators.filter(
                ({ processing_status }) =>
                    processing_status === ProcessingStatus.FINISHED || processing_status === ProcessingStatus.APPROVED,
            ) || [];
        return [...bookNarrators, ...doneNarrators];
    }, [narrators, bookNarrators]);

    const toggleShowNarrators = useCallback(() => {
        setShowNarrators(prev => !prev);
    }, []);

    const handleSelectNarrator = useCallback(
        (id: string) => () => {
            if (onNarratorChange) {
                onNarratorChange(id);
            }
            toggleShowNarrators();
        },
        [onNarratorChange, toggleShowNarrators],
    );

    const onNextTrackPress = useCallback(() => {
        if (chapters && selectedTrackIdx < chapters?.length - 1) {
            const idx = selectedTrackIdx + 1;
            setSelectedTrackIdx(idx);
            setSelectedChapter(chapters?.[idx]);

            if (audioRef.current) {
                audioRef.current.currentTime = 0;
                // audioRef.current.play();
            }
        }
    }, [chapters, selectedTrackIdx]);

    const onPrevTrackPress = useCallback(() => {
        if (chapters && selectedTrackIdx > 0) {
            const idx = selectedTrackIdx - 1;
            setSelectedTrackIdx(idx);
            setSelectedChapter(chapters?.[idx]);

            if (audioRef.current) {
                audioRef.current.currentTime = 0;
                // audioRef.current.play();
            }
        }
    }, [chapters, selectedTrackIdx]);

    const narratorName = useMemo(
        () =>
            getNarratorName({
                record: selectedNarrator,
                appLang: lang,
                fallbackLang: selectedNarrator?.lang,
            }),
        [selectedNarrator, lang],
    );

    // audio events handling
    useEffect(() => {
        let waitingTimeout: any;
        const audioEl = audioRef.current;
        const onWaiting = () => {
            waitingTimeout = setTimeout(() => {
                setIsAudioLoading(true);
            }, 200);
        };
        const onCanPlay = () => {
            clearTimeout(waitingTimeout);
            setIsAudioLoading(false);
        };

        const onPlay = () => {
            setIsPlaying(true);
        };

        const onPause = () => {
            setIsPlaying(false);
        };

        audioEl?.addEventListener('waiting', onWaiting);
        audioEl?.addEventListener('play', onPlay);
        audioEl?.addEventListener('playing', onPlay);
        audioEl?.addEventListener('pause', onPause);
        audioEl?.addEventListener('canplay', onCanPlay);
        audioEl?.addEventListener('ended', onNextTrackPress);

        return () => {
            audioEl?.removeEventListener('play', onPlay);
            audioEl?.removeEventListener('playing', onPlay);
            audioEl?.removeEventListener('pause', onPause);
            audioEl?.removeEventListener('waiting', onWaiting);
            audioEl?.removeEventListener('canplay', onCanPlay);
            audioEl?.removeEventListener('ended', onNextTrackPress);
        };
    }, [selectedAudioTrack, onNextTrackPress]);

    // media session actions
    useEffect(() => {
        if (navigator && navigator.mediaSession) {
            navigator.mediaSession.setActionHandler('play', () => {
                setIsPlaying(true);
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                setIsPlaying(false);
            });
            navigator.mediaSession.setActionHandler('nexttrack', onNextTrackPress);
            navigator.mediaSession.setActionHandler('previoustrack', onPrevTrackPress);
            navigator.mediaSession.setActionHandler('seekforward', () => {
                if (audioRef.current) {
                    audioRef.current.currentTime = audioRef.current.currentTime + 10;
                }
            });
            navigator.mediaSession.setActionHandler('seekbackward', () => {
                if (audioRef.current) {
                    audioRef.current.currentTime = audioRef.current.currentTime - 10;
                }
            });
            navigator.mediaSession.setActionHandler('seekto', details => {
                if (audioRef.current) {
                    // @ts-ignore
                    audioRef.current.currentTime = details.seekTime;
                }
            });
        }
        return () => {
            if ('mediaSession' in navigator) {
                navigator.mediaSession.setActionHandler('play', null);
                navigator.mediaSession.setActionHandler('pause', null);
                navigator.mediaSession.setActionHandler('nexttrack', null);
                navigator.mediaSession.setActionHandler('previoustrack', null);
                navigator.mediaSession.setActionHandler('seekforward', null);
                navigator.mediaSession.setActionHandler('seekbackward', null);
                navigator.mediaSession.setActionHandler('seekto', null);
            }
        };
    }, [onNextTrackPress, onPrevTrackPress]);

    // set to media session narrator, book info
    useEffect(() => {
        if (navigator && navigator.mediaSession) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: title,
                album: title,
                artist: narratorName,
                artwork: [
                    { src: cover, sizes: '96x96', type: 'image/png' },
                    // Add more artwork sizes as needed
                ],
            });
        }
        return () => {
            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = null;
            }
        };
    }, [title, cover, narratorName]);

    useEffect(() => {
        const audioEl = audioRef.current;
        if (audioEl) {
            audioEl.addEventListener('play', handlePlayPress);
            audioEl.addEventListener('pause', handlePausePress);
        }
        return () => {
            if (audioEl) {
                audioEl.removeEventListener('play', handlePlayPress);
                audioEl.removeEventListener('pause', handlePausePress);
            }
        };
    }, [handlePlayPress, handlePausePress]);

    const isBookInLibrary = useMemo(() => user?.library.some(userBook => userBook.smartBookId === bookId), [user, bookId]);
    const qrCodeData = qrCodeStorage.get();
    const showAddToLibrary = useMemo(() => !isBookInLibrary && qrCodeData?.bookId === bookId, [isBookInLibrary, qrCodeData, bookId]);
    const showByBtn = !isBookInLibrary && !(qrCodeData?.bookId === bookId) && !!bookByeLink;

    const toggleBell = useCallback(() => {
        setBellIsOn(prev => {
            bellChangeAudioCurrentTime = audioRef.current?.currentTime || 0;
            const isBellOn = !prev;
            toast.info(isBellOn ? t('pageTurnSoundOn') : t('pageTurnSoundOff'), {
                icon: isBellOn ? <BellIcon size={30} /> : <CrossedBell size={30} />,
            });
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.currentTime = bellChangeAudioCurrentTime;
                    // audioRef.current.pause();
                    audioRef.current.play();
                }
            }, 50);
            return isBellOn;
        });
    }, [t]);

    const toggleChapters = useCallback(() => {
        setOpenChapters(prev => !prev);
    }, []);

    const getChapterName = useCallback((names: LangName[], lang: Lang): string | undefined => {
        return names?.find(chapter => lang === chapter.lang)?.name || names?.[0]?.name || undefined;
    }, []);

    const handleSelectChapter = useCallback(
        (chapter: Chapter, idx: number) => () => {
            setSelectedTrackIdx(idx);
            setSelectedChapter(chapter);
            setOpenChapters(false);
            bellChangeAudioCurrentTime = 0;

            if (audioRef.current) {
                audioRef.current.currentTime = 0;
                // audioRef.current.play();
            }
        },
        [],
    );

    const nextTrackBtnDisabled = useMemo(() => {
        return chapters && selectedTrackIdx === chapters?.length - 1;
    }, [chapters, selectedTrackIdx]);

    const prevTrackBtnDisabled = useMemo(() => {
        return chapters && selectedTrackIdx === 0;
    }, [chapters, selectedTrackIdx]);

    const sizedCover = getSizedImage(cover, ImageSizesValues['280x280']);
    const sizedSelectedNarratorAvatar = selectedNarrator?.narratorAvatar.src
        ? getSizedImage(selectedNarrator.narratorAvatar.src, ImageSizesValues['140x140'])
        : null;

    const narratorAvatar = useMemo(() => {
        return selectedNarrator?.contributor?.avatar?.src || selectedNarrator?.narratorAvatar.src;
    }, [selectedNarrator]);

    if (!selectedNarrator) return null;

    return (
        <>
            {showMiniPlayer ? (
                <MiniPlayer
                    title={title}
                    author={author}
                    sizedCover={sizedCover}
                    isPlaying={isPlaying}
                    isAudioLoading={isAudioLoading}
                    audioRef={audioRef}
                    fullDuration={fullDuration}
                    chapterName={getChapterName(selectedChapter?.names || [], lang)}
                    selectedAudioTrack={selectedAudioTrack}
                    onMiniPlayerClose={onMiniPlayerClose}
                    toggleMiniPlayerMode={toggleMiniPlayerMode}
                    handlePausePress={handlePausePress}
                    handlePlayPress={handlePlayPress}
                />
            ) : (
                <div className={styles.some}>
                    <div className={styles.head}>
                        <picture className={styles.cover}>
                            {sizedCover?.webp && <source srcSet={sizedCover.webp} type='image/webp' />}
                            <img src={sizedCover?.jpg || sizedCover?.original} alt='' loading='lazy' />
                        </picture>

                        <div className={styles.narratorBox}>
                            <Narrator
                                // onClick={narratorList.length > 1 ? toggleShowNarrators : undefined}
                                name={narratorName}
                                lang={selectedNarrator.lang}
                                avatar={narratorAvatar}
                                label={t('narratedBy')}
                            />
                            {bookId && !isSample && (
                                <div className={styles.recBtnBox}>
                                    <RecordButton bookId={bookId} small bookLangs={bookLangs || []} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className={styles.titleBox}>
                            <div className={styles.titleInfo}>
                                <div className={styles.title}>{title}</div>
                                {author && <div className={styles.author}>{author}</div>}
                            </div>
                            {(showAddToLibrary || showByBtn) && isSample && (
                                <div className={styles.titleButton}>
                                    {showAddToLibrary && (
                                        <AddToLibrary buttonLabel={t('player.free')} small formats={bookAdditionalFormats || []} />
                                    )}
                                    {showByBtn && (
                                        <a href={bookByeLink} target='_blank' rel='noreferrer'>
                                            <Button fullWidth textUpperCase size='small'>
                                                {t('player.get')}
                                            </Button>
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.progressBox}>
                            <AudioProgressBar
                                audioTitle={getChapterName(selectedChapter?.names || [], lang)}
                                audioEl={audioRef.current}
                                totalTime={fullDuration}
                                updateAudioEvents={selectedAudioTrack} // need to re-render component when audio track changes to have correct progress bar and timings values set
                            />
                        </div>
                    </div>
                    <div className={styles.audioControls}>
                        {hasChapters && (
                            <button className={styles.prevBackBtn} onClick={onPrevTrackPress} disabled={prevTrackBtnDisabled}>
                                <PlayPrevIcon color='purple' size={40} />
                            </button>
                        )}
                        <button className={styles.secBtn} onClick={handleSecBackPress}>
                            <BackCircleArrow color='purple' size={48} />
                        </button>
                        <button
                            className={styles.pausePlay}
                            onClick={isPlaying ? handlePausePress : handlePlayPress}
                            // disabled={isAudioLoading}
                        >
                            {isAudioLoading ? (
                                <LoadingIcon color='white' size={34} />
                            ) : isPlaying ? (
                                <PauseIcon color='white' size={38} />
                            ) : (
                                <PlayIcon color='white' size={38} />
                            )}
                        </button>
                        <button className={styles.secBtn} onClick={handleSecNextPress}>
                            <ForwardCircleArrowIcon color='purple' size={48} />
                        </button>
                        {hasChapters && (
                            <button className={styles.prevBackBtn} onClick={onNextTrackPress} disabled={nextTrackBtnDisabled}>
                                <PlayNext color='purple' size={40} />
                            </button>
                        )}
                    </div>
                    <div className={styles.actions}>
                        <button className={styles.icoBtn} type='button' onClick={toggleBell}>
                            {bellIsOn ? <BellIcon size={30} /> : <CrossedBell size={30} />}
                        </button>
                        <button
                            className={styles.selectNarratorBtn}
                            type='button'
                            onClick={narratorList.length > 1 ? toggleShowNarrators : undefined}
                        >
                            <picture>
                                {sizedSelectedNarratorAvatar?.webp && (
                                    <source srcSet={sizedSelectedNarratorAvatar.webp} type='image/webp' />
                                )}
                                <img
                                    width={32}
                                    className={styles.mainImg}
                                    src={sizedSelectedNarratorAvatar?.jpg || sizedSelectedNarratorAvatar?.original}
                                    alt={narratorName}
                                    loading='lazy'
                                />
                            </picture>
                            <span>{narratorName}</span>
                            <EqualizerIcon size={20} />
                        </button>
                        <button className={styles.icoBtn} type='button' disabled={!chapters?.length} onClick={toggleChapters}>
                            <ListIcon size={32} />
                        </button>
                    </div>
                    <Narrators
                        selectedNarrator={selectedNarrator}
                        narratorList={narratorList}
                        open={showNarrators}
                        bookNarratorsLength={bookNarrators.length}
                        toggleShowNarrators={toggleShowNarrators}
                        handleSelectNarrator={handleSelectNarrator}
                    />
                    <Chapters
                        open={openChapters}
                        chapters={chapters}
                        sizedCover={sizedCover}
                        cover={cover}
                        selectedNarrator={selectedNarrator}
                        title={title}
                        author={author}
                        selectedChapter={selectedChapter}
                        toggleChapters={toggleChapters}
                        handleSelectChapter={handleSelectChapter}
                    />
                </div>
            )}
            <audio className={styles.audioTag} ref={audioRef} src={selectedAudioTrack} controls autoPlay />
        </>
    );
};

export default Player;
