import { ICustomMap } from "../types/ICustomMap";

export enum StorageKey {
    QR_CODE = 'qrCode',
    REMOTE_RECORD_SETUP_ACCOUNT = 'remoteRecordSetupAccount',
    REMOTE_RECORD_CODE = 'remoteRecordCode',
    NAVIGATE_ROUTE = 'navigateRoute',
}

export abstract class BrowserStorage<T> {
    constructor(protected readonly key: StorageKey, private readonly defaultValue: T) {}

    protected getStorage(): T {
        let storage = localStorage.getItem(this.key);

        if (!storage) {
            return this.setStorage(this.defaultValue);
        }

        try {
            return JSON.parse(storage);
        } catch {
            return this.setStorage(this.defaultValue);
        }
    }

    protected setStorage(storage: T): T {
        localStorage.setItem(this.key, JSON.stringify(storage));
        return storage;
    }

    protected updateStorage(storage: Partial<T>): T {
        localStorage.setItem(
            this.key,
            JSON.stringify({
                ...this.getStorage(),
                ...storage,
            }),
        );
        return this.getStorage();
    }

    protected removeStorage() {
        localStorage.removeItem(this.key);
    }
}


export const setLocalStorage = (key: string, value: ICustomMap): void => {
    localStorage.setItem(key, JSON.stringify(value))
}


export const getLocalStorage = (key: string): ICustomMap | null => {
    const storage = localStorage.getItem(key)
    if (storage) {
        return JSON.parse(storage)
    }
    return null;
}

export const removeLocalStorage = (key: string): void => {
    localStorage.removeItem(key)
}
