import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/global.scss';
import './firebase';

import reportWebVitals from './reportWebVitals';
import LangContextProvider from 'context/lang';
import AudioContextProvider from './context/audio';

import { isProd } from 'utils/isProd';
import AuthContextProvider from './context/auth';
import App from './components/App';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';

import './i18n';

// Initialize React Ga with your tracking ID
if (window?.location.hostname === "play.bettertimestories.com") {
    ReactGA.initialize('G-Z13RQ8PTHF');
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 30000,
            retry: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
            <LangContextProvider>
                <AudioContextProvider>
                    <App />
                </AudioContextProvider>
            </LangContextProvider>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>,
);

window.console.log('isProd', isProd);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
