import React, { FC } from 'react';
import styles from './styles.module.scss';
import { flags } from '../../../../../config/flags';
import { Lang } from '../../../../../config/lang';
import { getSizedImage, ImageSizesValues } from "../../../../../utils/getSizedImage";
import { DEFAULT_AVATAR_SRC } from "../../../../../config/common";

type Props = {
    avatar?: string;
    name: string;
    label: string;
    lang: Lang;
};
const Narrator: FC<Props> = ({ avatar, name, lang, label }) => {
    const sizedAvatar = avatar ? getSizedImage(avatar, ImageSizesValues["140x140"]) : null;
    const avatarImg = sizedAvatar?.jpg || sizedAvatar?.original || DEFAULT_AVATAR_SRC;

    return (
        <div className={styles.narrator}>
            <picture>
                {
                    sizedAvatar?.webp && (
                        <source srcSet={sizedAvatar.webp} type='image/webp' />
                    )
                }
                <img className={styles.mainImg} src={avatarImg} alt={name} loading='lazy' />
                <div className={styles.lang}>
                    <img src={flags[lang]} alt={lang} loading='lazy' />
                </div>
            </picture>
            <div className={styles.name}>
                <span className={styles.title}>{label}</span>
                <p>
                    {name}
                </p>
            </div>
        </div>
    );
};

export default Narrator;
