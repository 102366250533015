import React, { FC, ReactNode } from "react";
import styles from './styles.module.scss';
import cn from 'classnames';

type Props = {
    primary?: boolean;
    sticky?: boolean;
    className?: string;
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    children?: ReactNode;
};
const Header: FC<Props> = ({ primary, sticky, className, children, rightSlot, leftSlot }) => {
    return (
        <header className={cn(styles.header, { [styles.primary]: primary, [className || '']: className, [styles.sticky]: sticky })}>
            <div className={styles.actions}>{leftSlot}</div>
            <div className={styles.center}>{children}</div>
            <div className={cn(styles.actions, styles.rightSlot)}>{rightSlot}</div>
        </header>
    );
};

export default Header;
