import {FunctionComponent, useEffect} from 'react';

import RoutesRoot from '../../Routes';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {themeColors} from '../../config/theme';
// import {useServiceWorker} from '../../hooks/useServiceWorker';
// import styles from './styles.module.scss';
// import { useTranslation } from 'react-i18next';
import AudioPlayer from '../AudioPlayer';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const App: FunctionComponent = () => {
    // const { t } = useTranslation('common');
    useEffect(() => {
        const element = document.getElementById('load');
        if (element) element.style.display = 'none';
        const theme = document.querySelector('meta[name="theme-color"]');
        theme?.setAttribute('content', themeColors.secondary);
        // temporary disable service worker
        serviceWorkerRegistration.unregister();
    }, []);

    // const { showReload, reloadPage } = useServiceWorker();

    return (
        <main>
            <BrowserRouter>
                <RoutesRoot />
                <AudioPlayer />
            </BrowserRouter>

            <ToastContainer position='top-right' hideProgressBar />

            {/*{showReload && (*/}
            {/*    <div className={styles.updatesModal}>*/}
            {/*        <p>{t('newAppVersAvailable')}</p>*/}
            {/*        <button type='button' onClick={reloadPage}>*/}
            {/*            {t('refresh')}*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*)}*/}
        </main>
    );
};

export default App;
