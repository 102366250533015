import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IRecord, ProcessingStatus } from '../types/IRecord';
import { getImageByLang } from "../utils/langUtils";
import { LangContext } from './lang';
import { BookFormat, Chapter } from "../types/IBook";
import { AuthContext } from './auth';
import useBook from "../hooks/useBook";
import { Lang } from "../config/lang";

type AudioContextProps = {
    selectedRecordId: string | null;
    selectedBookId: string | null;
    isPlayerOpen: boolean;
    miniPlayerMode: boolean;
    isSample: boolean;
    cover: string | null;
    authorName: string | null;
    selectedNarrator: IRecord | null;
    chapters: Chapter[] | undefined;
    canPlayRecord: boolean;
    isLoading: boolean;
    bookName: string | undefined;
    bookLangs: Lang[];
    bookNarrators?: IRecord[];
    narrators?: IRecord[];
    bookSampleRecords?: IRecord[] | null;
    additionalFormats: BookFormat[];
    shopLink?: string;
    openPlayer: (recordId: string, bookId: string) => void;
    closePlayer: () => void;
    handleNarratorChange: (recordId: string) => void;
    toggleMiniPlayerMode: () => void;
};

type AudioContextProviderProps = {
    children: ReactNode;
};

const initData: AudioContextProps = {
    selectedRecordId: null,
    selectedBookId: null,
    isPlayerOpen: false,
    miniPlayerMode: false,
    isSample: false,
    cover: null,
    authorName: null,
    bookLangs: [],
    selectedNarrator: null,
    chapters: undefined,
    canPlayRecord: false,
    isLoading: false,
    bookName: undefined,
    bookNarrators: [],
    narrators: [],
    bookSampleRecords: [],
    additionalFormats: [],
    shopLink: undefined,
    openPlayer: () => {},
    closePlayer: () => {},
    handleNarratorChange: () => {},
    toggleMiniPlayerMode: () => {},
};

type CacheData = {
    recordId: string;
    bookId: string;
    openPlayer: boolean;
    isMiniOn: boolean;
};

const cacheKey = 'btsPlayer';
const initCache = localStorage.getItem(cacheKey);
const cachedData: CacheData | null = initCache ? JSON.parse(initCache) : null;

export const AudioContext = createContext(initData);

const AudioContextProvider: FC<AudioContextProviderProps> = ({ children }) => {
    const [selectedRecordId, setSelectedRecordId] = useState<string | null>(cachedData?.recordId || null);
    const [selectedBookId, setSelectedBookId] = useState<string | null>(cachedData?.bookId || null);
    const [isPlayerOpen, setOpenPlayer] = useState<boolean>(cachedData?.openPlayer || false);
    const [miniPlayerMode, setMiniPlayerMode] = useState<boolean>(cachedData?.isMiniOn || false);

    const { lang } = useContext(LangContext);
    const { isAuthenticated } = useContext(AuthContext);

    const { isLoading, isBookNarratorsLoading, book, userRecords, bookSampleRecords, bookNarrators, bookAuthorsName } = useBook(selectedBookId || '');

    useEffect(() => {
        localStorage.setItem(
            cacheKey,
            JSON.stringify({
                recordId: selectedRecordId,
                bookId: selectedBookId,
                openPlayer: isPlayerOpen,
                isMiniOn: miniPlayerMode,
            }),
        );
    }, [selectedBookId, selectedRecordId, isPlayerOpen, miniPlayerMode]);

    const openPlayer = useCallback((recordId: string, bookId: string) => {
        setSelectedRecordId(recordId);
        setSelectedBookId(bookId);
        setOpenPlayer(true);
    }, []);

    const closePlayer = useCallback(() => {
        setSelectedRecordId(null);
        setSelectedBookId(null);
        setOpenPlayer(false);
        setMiniPlayerMode(false);
    }, []);

    const selectedNarrator = useMemo<IRecord | null>(() => {
        if (!selectedRecordId) {
            return null;
        }

        return (
            [...(userRecords || []), ...(bookNarrators || []), ...(bookSampleRecords || [])].find(({ id }) => id === selectedRecordId) ?? null
        );
    }, [selectedRecordId, userRecords, bookNarrators, bookSampleRecords]);

    const isSample = useMemo(() => !!book?.sampleRecordingList?.includes(selectedRecordId || ''), [selectedRecordId, book]);

    const cover: string | null = useMemo(() => {
        if (!book) return null;
        const image = getImageByLang({
            images: [...(book?.coverImgAudiobook || []), ...(book?.coverImgEbook || [])],
            appLang: lang,
            fallbackLang: book?.inLanguage?.[0],
        });
        return image || book?.coverImgSquare?.src || book?.coverImg?.src || null;
    }, [book, lang]);

    const chapters = useMemo(() => book?.chapters?.sort((a, b) => a.chapterId - b.chapterId), [book]);

    // const authorName = useMemo(
    //     () =>
    //         getAuthorName({
    //             author: bookAuthor,
    //             appLang: lang,
    //             fallbackLang: book?.inLanguage?.[0] || Lang.en,
    //         }),
    //     [bookAuthor, lang, book],
    // );

    const bookName = useMemo(() => book?.name, [book]);
    const additionalFormats = useMemo(() => book?.additionalFormats || [], [book]);
    const shopLink = useMemo(() => book?.discoveryMarket?.[0]?.shopLink, [book]);

    const handleNarratorChange = useCallback((id: string) => {
        setSelectedRecordId(id);
    }, []);

    const toggleMiniPlayerMode = useCallback(() => {
        setMiniPlayerMode(prev => !prev);
    }, []);

    const canPlayRecord = useMemo(() => {
        // only authed users can play full records
        if (!isAuthenticated && !isSample) {
            closePlayer();
            return false;
        }
        // user recording is finished
        if (
            selectedNarrator?.processing_status &&
            (selectedNarrator?.processing_status === ProcessingStatus.FINISHED ||
                selectedNarrator.processing_status === ProcessingStatus.APPROVED)
        ) {
            return true;
        }

        // has chapters
        if (selectedNarrator?.chapters?.length) {
            return true;
        }

        // fallback, has generated mp3 file
        return !!selectedNarrator?.full_audio?.src || !!selectedNarrator?.full_transition_audio?.src;
    }, [selectedNarrator, isAuthenticated, isSample, closePlayer]);

    return (
        <AudioContext.Provider
            value={{
                selectedRecordId,
                selectedBookId,
                openPlayer,
                miniPlayerMode,
                isSample,
                cover,
                authorName: bookAuthorsName,
                selectedNarrator,
                chapters,
                canPlayRecord,
                isLoading: isLoading || isBookNarratorsLoading,
                bookName,
                bookNarrators,
                bookLangs: book?.inLanguage || [],
                narrators: userRecords,
                bookSampleRecords,
                additionalFormats,
                shopLink,
                isPlayerOpen,
                closePlayer,
                handleNarratorChange,
                toggleMiniPlayerMode,
            }}
        >
            {children}
        </AudioContext.Provider>
    );
};
export default AudioContextProvider;
