import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export type Props = {
    labelText: string;
    size?: 'medium' | 'large';
    bgColor?: 'white';
    required?: boolean;
    children: React.ReactElement;
    value?: string | number | boolean | string[] | null | undefined;
};

const FloatingLabel: FC<Props> = ({ labelText, children, value, size, bgColor, required }) => {
    const [active, setActive] = useState(false);

    const onFocus = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (args: SyntheticEvent<any>) => {
            children.props?.onFocus?.(args);
            setActive(true);
        },
        [children.props],
    );

    const onBlur = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (args: SyntheticEvent<any>) => {
            children.props?.onBlur?.(args);
            setActive(false);
        },
        [children.props],
    );

    const hasValue = Array.isArray(value) ? !!value.length : value;

    const component = React.cloneElement(children, { onFocus, onBlur });
    const float = active || hasValue;
    return (
        <div className={styles.box}>
            {component}
            <span
                className={cn(styles.label, {
                    [styles.floated]: float,
                    [styles[size || '']]: size,
                    [styles[bgColor || '']]: bgColor,
                })}
            >
                {labelText}
                {required && <em className={styles.required}>*</em>}
            </span>
        </div>
    );
};

export default FloatingLabel;
