import React, { FunctionComponent, useContext, useEffect } from 'react';
import Loader from '../Loader';
import Player from '../common/Player';
import styles from './styles.module.scss';
import { AudioContext } from '../../context/audio';
import { useTranslation } from 'react-i18next';
import MinimizeIcon from '../icons/Minimize';
import cn from 'classnames';
import { useSwipeable } from '../../utils/Swipe/Swipe';

const AudioPlayer: FunctionComponent = () => {
    const {
        isPlayerOpen,
        isLoading,
        selectedBookId,
        bookName,
        authorName,
        bookLangs,
        cover,
        isSample,
        selectedNarrator,
        narrators,
        bookNarrators,
        bookSampleRecords,
        chapters,
        additionalFormats,
        shopLink,
        canPlayRecord,
        miniPlayerMode,
        handleNarratorChange,
        closePlayer,
        toggleMiniPlayerMode,
    } = useContext(AudioContext);

    const { t } = useTranslation('common');

    useEffect(() => {
        const root = document.getElementById('root');
        const open = isPlayerOpen && !miniPlayerMode;
        // when player is open, we need to hide the body scroll
        if (root) {
            // when player is open, we need to hide the page scroll, we set it to html directly because we have body overflow hidden change in use
            document.documentElement.style.overflow = open ? 'hidden' : 'unset';
            root.style.maxHeight = open ? '100vh' : 'unset';
        }

        // when mini player is open, we need to add padding bottom to body to make sure the player is not hidden behind the footer
        if (miniPlayerMode && root) {
            root.style.paddingBottom = '100px';
        } else if (root) {
            // when mini player is closed, we need to remove the padding bottom
            root.style.paddingBottom = '0';
        }

        return () => {
            // when player is closed, we need to show the body scroll and remove the padding bottom
            if (root) {
                document.documentElement.style.overflow = 'unset';
                root.style.maxHeight = 'unset';
            }
        };
    }, [isPlayerOpen, miniPlayerMode]);

    const containerRef = React.useRef<HTMLElement>(null);
    const swipingElRef = React.useRef<HTMLDivElement>();
    const refPassthrough = (el: any) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);

        // set myRef el so you can access it yourself
        swipingElRef.current = el;
    };

    const handlers = useSwipeable({
        onSwiping: data => {
            if (swipingElRef.current && !miniPlayerMode && containerRef.current) {
                if (data.dir.toLowerCase() === 'down') {
                    swipingElRef.current.style.transform = `translateY(${data.deltaY}px)`;
                    // prevent parent scroll when swiping up/down
                    containerRef.current.style.overflowY = 'hidden';
                }
            }
        },
        onSwiped: data => {
            if (swipingElRef.current && !miniPlayerMode && containerRef.current) {
                const elHeight = swipingElRef.current?.offsetHeight;
                const swipeDown = data.dir.toLowerCase() === 'down';
                // when swiping down more than 50% of the player height, on mini mode
                if (data.absY >= elHeight / 2.5 && swipeDown) {
                    toggleMiniPlayerMode?.();
                }
                // remove the transform value to make sure the player is back to its original position
                swipingElRef.current.style.transform = `translateY(0)`;
                // remove the overflow hidden to make sure parent scroll is back to normal
                containerRef.current.style.overflowY = 'auto';
            }
        },
    });

    if (!isPlayerOpen || !selectedBookId) {
        return null;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <section ref={containerRef} className={cn(styles.audioPlayer, { [styles.isMini]: miniPlayerMode })}>
            <div className={styles.inner} {...handlers} ref={refPassthrough}>
                {!miniPlayerMode && (
                    <header className={styles.header}>
                        {/*<button onClick={closePlayer}>*/}
                        {/*    <ArrowLeft size={24} color='purple' />*/}
                        {/*</button>*/}
                        <span />
                        <button onClick={toggleMiniPlayerMode}>
                            <MinimizeIcon size={24} color='purple' />
                        </button>
                    </header>
                )}
                {canPlayRecord ? (
                    <Player
                        bookId={selectedBookId}
                        bookLangs={bookLangs}
                        title={bookName || ''}
                        author={authorName}
                        cover={cover || ''}
                        narrators={narrators || []}
                        bookNarrators={isSample ? bookSampleRecords || [] : bookNarrators || []}
                        selectedNarrator={selectedNarrator}
                        isSample={isSample}
                        onNarratorChange={handleNarratorChange}
                        bookAdditionalFormats={additionalFormats}
                        bookByeLink={shopLink}
                        chapters={chapters}
                        showMiniPlayer={miniPlayerMode}
                        toggleMiniPlayerMode={toggleMiniPlayerMode}
                        onMiniPlayerClose={closePlayer}
                    />
                ) : (
                    <div className={styles.errMsg}>{t('playRecordErrorMsg')}</div>
                )}
            </div>
        </section>
    );
};
export default AudioPlayer;
