import React, { FC, useCallback, useContext } from 'react';
import styles from './styles.module.scss';
import recordImage from './assets/record.png';
import listenImage from './assets/listen.png';
import libraryImage from './assets/library.png';
import Button from '../Button';
import classNames from 'classnames';
import { AuthContext } from '../../../context/auth';
import { useTranslation } from 'react-i18next';
import Header from '../Headers/Header';
import BackButton from '../Headers/components/BackButton';

const steps = [1, 2, 3];

const Onboarding: FC = () => {
    const { handleOnboardingPass } = useContext(AuthContext);
    const { t } = useTranslation('sign-up');
    const [activeStep, setActiveStep] = React.useState(1);

    const goNext = useCallback(() => {
        if (activeStep === steps.length) {
            handleOnboardingPass();
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, handleOnboardingPass]);

    const goBack = useCallback(() => {
        if (activeStep === 1) {
            return;
        }
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    return (
        <section className={styles.root}>
            <Header
                leftSlot={activeStep !== 1 && <BackButton onBackPress={goBack} />}
                rightSlot={
                    <button onClick={handleOnboardingPass} className={styles.skipBtn}>
                        Skip
                    </button>
                }
            >
                {t('onboarding')}
            </Header>
            <div className={styles.content}>
                {activeStep === 1 && (
                    <>
                        <img src={recordImage} alt='' />
                        <h2>{t('benefitsRecordTitle')}</h2>
                        <p>{t('benefitsRecordText')}</p>
                    </>
                )}
                {activeStep === 2 && (
                    <>
                        <img src={listenImage} alt='' />
                        <h2>{t('benefitsListenTitle')}</h2>
                        <p>{t('benefitsListenText')}</p>
                    </>
                )}
                {activeStep === 3 && (
                    <>
                        <img src={libraryImage} alt='' />
                        <h2>{t('benefitsListenText')}</h2>
                        <p>{t('benefitsLibraryText')}</p>
                    </>
                )}
            </div>
            <ul className={styles.navigation}>
                {steps.map(step => (
                    <li key={step}>
                        <button className={classNames({ [styles.selected]: step === activeStep })} />
                    </li>
                ))}
            </ul>
            <section className={styles.actions}>
                <Button onClick={goNext} fullWidth textUpperCase>
                    {t('next')}
                </Button>
            </section>
        </section>
    );
};

export default Onboarding;
