import React, { FC, useCallback, useContext, useMemo } from "react";
import styles from './styles.module.scss';
import { formatTime } from '../../../../../hooks/useTimer';
import cn from 'classnames';
import Curtain from '../../../Curtain';
import { ImageSizes } from '../../../../../utils/getSizedImage';
import { useTranslation } from 'react-i18next';
import { IRecord } from '../../../../../types/IRecord';
import { Chapter, LangName } from '../../../../../types/IBook';
import { Lang } from '../../../../../config/lang';
import { LangContext } from '../../../../../context/lang';

type Props = {
    open: boolean;
    cover: string;
    title: string;
    author: string | null | undefined;
    sizedCover: ImageSizes | null;
    chapters?: Chapter[];
    selectedChapter?: Chapter;
    selectedNarrator: IRecord;
    toggleChapters: () => void;
    handleSelectChapter: (chapter: Chapter, idx: number) => () => void;
};
const Chapters: FC<Props> = ({
    open,
    sizedCover,
    cover,
    title,
    author,
    chapters,
    selectedChapter,
    toggleChapters,
    selectedNarrator,
    handleSelectChapter,
}) => {
    const { t } = useTranslation('common');
    const { lang } = useContext(LangContext);

    const getChapterName = useCallback((names: LangName[], lang: Lang): string | undefined => {
        return names?.find(chapter => lang === chapter.lang)?.name || names?.[0]?.name || undefined;
    }, []);

    const getChapterDuration = useCallback(
        (id: number): string => {
            const duration = selectedNarrator?.chapters?.find(({ chapterId }) => chapterId.toString() === id.toString())?.duration_ms || 0;
            return formatTime(duration);
        },
        [selectedNarrator],
    );

    const totalChaptersDuration = useMemo(() => {
        return (
            selectedNarrator?.chapters?.reduce((acc: number, { duration_ms }) => {
                acc += duration_ms || 0; // milliseconds
                return acc;
            }, 0) || 0
        );
    }, [selectedNarrator]);

    return (
        <Curtain open={open} onClose={toggleChapters}>
            <div className={styles.chapters}>
                <div className={styles.chapterBook}>
                    <picture>
                        {sizedCover?.webp && <source srcSet={sizedCover.webp} type='image/webp' />}
                        <img src={sizedCover?.jpg || sizedCover?.original || cover} alt='' loading='lazy' />
                    </picture>
                    <div className={styles.info}>
                        <h3>{title}</h3>
                        {author && <span className={styles.author}>{author}</span>}
                        {totalChaptersDuration && <span className={styles.duration}>{formatTime(totalChaptersDuration)}</span>}
                    </div>
                </div>
                <h4>{t('selectChapter')}</h4>
                <ul className={styles.chapterList}>
                    {chapters?.map((chapter, idx) => {
                        const isActive = selectedChapter?.chapterId === chapter.chapterId;
                        return (
                            <li
                                key={chapter.chapterId}
                                className={cn({ [styles.active]: isActive })}
                                onClick={handleSelectChapter(chapter, idx)}
                            >
                                <span className={styles.chapterTitle}>{getChapterName(chapter.names, lang)}</span>
                                <span className={styles.chapterInfo}>{getChapterDuration(chapter.chapterId)}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Curtain>
    );
};

export default Chapters;
