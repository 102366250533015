import React, { FC } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    error?: boolean;
    bgColor?: 'white';
    fieldSize?: 'medium' | 'large';
}

const Input: FC<IProps> = ({ className, error, bgColor, fieldSize, ...attributes }) => {
    return (
        <input
            className={cn(styles.input, {
                [className || '']: className,
                [styles.error]: error,
                [styles[bgColor || '']]: bgColor,
                [styles[fieldSize || '']]: fieldSize,
            })}
            {...attributes}
        />
    );
};

export default Input;
