import React, { ButtonHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import LoadingIcon from '../../icons/Loading';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
    className?: string;
    textUpperCase?: boolean;
    fullWidth?: boolean;
    ghost?: boolean;
    size?: 'xs' | 'small' | 'medium' | 'large';
    color?: 'red' | 'white' | 'gray' | 'blue' | 'black' | 'yellow';
    icon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
}

const Button: FC<Props> = ({
    children,
    textUpperCase,
    fullWidth,
    className,
    ghost,
    size,
    icon,
    isLoading,
    color,
    ...attributes
}) => {
    return (
        <button
            className={cn(styles.button, {
                [className as string]: className,
                [styles.textUpperCase]: textUpperCase,
                [styles.fullWidth]: fullWidth,
                [styles.ghost]: ghost,
                [styles[size || '']]: size,
                [styles[color || '']]: color,
            })}
            {...attributes}
        >
            {!isLoading && icon && <span className={styles.icon}>{icon}</span>}
            {isLoading && (
                <span className={styles.icon}>
                    <LoadingIcon size={20} color={ghost ? 'black' : 'white'} />
                </span>
            )}
            {children}
        </button>
    );
};

export default Button;
