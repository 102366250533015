import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { AuthContext } from '../context/auth';
import { Navigate } from 'react-router-dom';
import routes from '../config/routes';
import Loader from "../components/Loader";
import Onboarding from "../components/common/Onboarding";

interface Props extends PropsWithChildren {
    acceptAnonymous?: boolean;
}

const AuthGuard: FC<Props> = ({ children, acceptAnonymous }) => {
    const { isAuthenticated, isAuthedAnonymous, signInAnonymousUser, isOnboardingPassed } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated && acceptAnonymous) {
            signInAnonymousUser();
        }
    }, [isAuthenticated, acceptAnonymous, signInAnonymousUser]);

    if (!isAuthenticated && !acceptAnonymous) {
        return <Navigate to={routes.accountSignUp} replace />;
    }

    if (acceptAnonymous && !isAuthenticated && !isAuthedAnonymous) {
        return <Loader />;
    }

    if (!isOnboardingPassed && !acceptAnonymous) {
        return <Onboarding />;
    }

    return <>{children}</>;
};

export default AuthGuard;
