import { doc, DocumentData, getDoc, setDoc, updateDoc } from "firebase/firestore/lite";
import { db } from "../firebase";
import { FirebaseCollection } from "../utils/firebase";
import { IUser } from "../types/IUser";

export const hydrateUser = (id: string, data: DocumentData): IUser => {
    const initData: IUser = {
        id: '',
        name: null,
        firstName: null,
        lastName: null,
        createdAt: '',
        updatedAt: '',
        app_shipping_country: null,
        kidsName: null,
        relationshipToChild: '',
        avatar: null,
        library: [],
        isAnonymous: false,
        following: {
            authorIDs: [],
        }
    };

    return {
        ...initData,
        ...data,
        id,
    };
};


export type ReqUpdateUser = {
    id: string;
    data: Partial<IUser>;
}
export const requestUpdateUser = async ({id, data}: ReqUpdateUser): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.USER, id);
    return updateDoc(recordRef, data);
}

export const requestUser = async (id?: string): Promise<IUser | null> => {
    if (!id) return null;
    const ref = doc(db, FirebaseCollection.USER, id);
    const snapshot = await getDoc(ref);
    const data = snapshot.data();

    if (!data) {
        return null;
    }

    return hydrateUser(ref.id, data);
};

export const createUser = async ({ id, ...data }: IUser): Promise<string> => {
    const user: Omit<IUser, 'id'> = {
        ...data,
        library: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
    };

    const ref = doc(db, FirebaseCollection.USER, id);
    await setDoc(doc(db, FirebaseCollection.USER, id), user);
    return ref.id;
};
