import { doc, DocumentData, getDoc, updateDoc } from "firebase/firestore/lite";
import { db } from '../firebase';
import { FirebaseCollection } from '../utils/firebase';
import { Author } from '../types/IBook';

export const getAuthorQueryKey = 'author';
export const getAuthorsQueryKey = 'authors';

export const hydrateAuthor = (authorId: string, data: DocumentData): Author => {
    const initData: Author = {
        id: '',
        // name: '',
        firstName: '',
        lastName: '',
        names: [],
        types: [],
        contributed: {
            bookIDs: [],
        },
        followers: {
            count: 0,
            userIDs: [],
        }
    };

    return {
        ...initData,
        ...data,
        id: authorId,
    };
};

export const requestAuthor = async (id?: string): Promise<Author | null> => {
    if (!id) return null;
    const docRef = doc(db, FirebaseCollection.AUTHOR, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (!data) {
        return null;
    }
    return hydrateAuthor(docRef.id, data);
};

export const requestAuthors = async (ids: string[]): Promise<Author[]> => {
    if (ids.length < 1) {
        return [];
    }
    return await Promise.all(ids.map(requestAuthor)).then(list =>
        list.reduce((list: Author[], author) => (author ? [...list, author] : list), []),
    );
};

export type ReqUpdateAuthor = {
    id: string;
    data: Partial<Author>;
}
export const requestUpdateAuthor = async ({id, data}: ReqUpdateAuthor): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.AUTHOR, id);
    return updateDoc(recordRef, data);
}
