import { useQuery } from 'react-query';
import { IQrCode } from '../types/IQrCode';
import qrCodeStorage from '../storage/qr-code';
import { SmartBookQrCode, SmartObjectQrCode } from '../utils/qrCode';
import { QueryKey } from '.';

const fetchQrCodeByQuery = async (query: URLSearchParams): Promise<IQrCode | null> => {
    const key = query.get('key');
    if (key) {
        const qrCode = await SmartObjectQrCode.create(key);
        return qrCode.value();
    }
    const bookKey = query.get('bookkey');
    if (bookKey) {
        const qrCode = await SmartBookQrCode.create(bookKey);
        return qrCode.value();
    }
    return null;
};

const fetchQrCode = async (query?: URLSearchParams): Promise<IQrCode | null> => {
    const storedQrCode = qrCodeStorage.get();
    if (storedQrCode && !query?.get('bookkey')) {
        return storedQrCode;
    }

    if (!query) {
        return null;
    }

    return fetchQrCodeByQuery(query);
};

export const useQrCode = (query?: URLSearchParams) => {
    return useQuery<IQrCode | null>([QueryKey.GET_QR_CODE], () => fetchQrCode(query), {
        staleTime: 0, // Infinity,
        onSuccess: qrCode => qrCodeStorage.set(qrCode),
    });
};
