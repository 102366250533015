import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { SvgColor } from './types';
import { getSvgColor } from './helpers/color';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type SvgProviderProps = PropsWithChildren<{
    color?: SvgColor;
    size?: number;
    className?: string;
}>;

export const SvgProvider: FunctionComponent<SvgProviderProps> = ({ color, className, size = 24, children }) => {
    const sx: React.CSSProperties = {
        width: size,
        height: size,
    };

    if (color) {
        sx.color = getSvgColor(color);
    }

    return (
        <i role='img' className={classNames(styles.container, className)} style={sx}>
            {children}
        </i>
    );
};

export const SvgFactory = (children: ReactNode): FunctionComponent<SvgProviderProps> => {
    return props => {
        return <SvgProvider {...props}>{children}</SvgProvider>;
    };
};
