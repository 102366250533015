import { AvatarSize } from "./types";
import { colors } from "../../../config/common";

export const getInitials = (name: string) => {
    const names = name.split(' ');
    const firstNameInitial = names[0] ? names[0].charAt(0).toUpperCase() : '';
    const lastNameInitial = names.length > 1 && names[names.length - 1] ? names[names.length - 1].charAt(0).toUpperCase() : '';
    return `${firstNameInitial}${lastNameInitial}`;
};

export const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
};


export const calculateSvgSize = (size: AvatarSize = AvatarSize.L): number => {
    switch (size) {
        case AvatarSize.XL3:
            return 80;
        case AvatarSize.XL2:
            return 62;
        case AvatarSize.XL:
            return 38;
        case AvatarSize.L:
            return 30;
        case AvatarSize.M:
            return 20;
        case AvatarSize.S:
            return 16;
        case AvatarSize.XS:
            return 10;
        default: return 30;
    }
};
