import { Lang } from 'config/lang';
import { Author } from "./IBook";

export interface IRecordPage {
    duration?: number;
    pageNum: number;
    src: string;
    clientDuration?: number;
}

export type RecChapter = {
    chapterId: number | string;
    duration_ms: number;
    full_audio: {
        src: string;
        title: string;
    }
    full_transition_audio: {
        src: string;
        title: string;
    }
}

export type NarratorName = {
    text: string;
    lang: Lang;
}

export enum ProcessingStatus {
    STARTED = 'STARTED',
    STARTING_ERROR = 'STARTING_ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    INPROGRESS_ERROR = 'INPROGRESS_ERROR',
    FINISHED = 'FINISHED',
    APPROVED = 'APPROVED',
}

export interface IRecord {
    id: string;
    createdAt: string;
    createdBy: string;
    lang: Lang;
    narratorAvatar: {
        src: string;
    };
    narratorVideoIntro: {
        src: string;
    };
    // name: string;
    narratorName: string;
    // narratorNames: NarratorName[];
    pages: IRecordPage[];
    smartObjectBook: string;
    smartObject: string;
    narratorUserId: string | null; // user id who creates the record
    full_audio?: {
        src: string | null;
    };
    full_transition_audio?: {
        src: string | null;
    };
    full_transition_audio_intro_offset: number;
    // isOriginalNarration: boolean;
    sampleAudio: {
        src: string;
    }
    sampleDurationMs: number;
    processing_status: ProcessingStatus | '';
    processing_started_at?: {
        seconds: number;
        nanoseconds: number;
    };
    processing_message?: string;
    message?: string;
    chapters?: RecChapter[];
    recordingType: 'local' | 'remote'; // user who owns the book recording "local", 'remote' - shared recording
    canEdit?: boolean; // we lock shared recordings after it is recorder to avoid re recordings in case if link shared to many other humans
    familyRelationship?: string; // shared/local recordings only - to display relation ship to kid like father/sister...
    contributorID?: string;
    contributor?: Author; // it is ui only
}
