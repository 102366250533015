import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { AuthContext } from '../context/auth';
import { Navigate } from 'react-router-dom';
import routes from '../config/routes';

const AnonGuard: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    if (isAuthenticated) {
        return <Navigate to={routes.library} replace />;
    }

    return <>{children}</>;
};

export default AnonGuard;
