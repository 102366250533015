export enum ImageSizesValues {
    '140x140'= '140x140',
    '240x240' = '240x240',
    '280x280' = '280x280',
    '320x320' = '320x320',
    '480x480' = '480x480',
    '640x640' = '640x640',
    '920x920' = '920x920',
    '1280x1280' = '1280x1280',
    '312x360' = '312x360',
    '624x720' = '624x720',
    '320x360' = '320x360',
    '640x720' = '640x720',
    '210x105' = '210x105',
    '420x210' = '420x210',
    '840x420' ='840x420'
}

export type ImageSizes = {
    webp: string;
    original: string;
    jpg: string | null;
}

export const getSizedImage = (image: string | null | undefined, size: ImageSizesValues): ImageSizes | null => {
    if (!image) return null;
    let pathToFile = image.replace('https://firebasestorage.googleapis.com/v0/b/iks-book.appspot.com/o/', '');
    pathToFile = decodeURIComponent(pathToFile.split('?')[0]);

    // Remove extension if exists
    pathToFile = pathToFile.replace(/\.[^/.]+$/, "");

    // Get folder and file name
    const lastSlashIndex = pathToFile.lastIndexOf("/");
    const pathToFolder = pathToFile.substring(0, lastSlashIndex);
    const fileName = pathToFile.substring(lastSlashIndex + 1);

    // Thumb folder
    const thumbFolder = 'thumb';

    return {
        webp: `https://storage.googleapis.com/iks-book.appspot.com/${pathToFolder}/${thumbFolder}/${fileName}_${size}.webp`,
        original: image,
        jpg: null,
    };
}
