import { SvgFactory } from '../../Svg';

const RecordVoiceIcon = SvgFactory(
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='black'>
        <path d='M5.25108 7.71509C6.53974 7.71509 7.58441 6.67042 7.58441 5.38176C7.58441 4.09309 6.53974 3.04842 5.25108 3.04842C3.96241 3.04842 2.91774 4.09309 2.91774 5.38176C2.91774 6.67042 3.96241 7.71509 5.25108 7.71509Z' />
        <path d='M5.25108 8.88176C3.69358 8.88176 0.584412 9.66342 0.584412 11.2151V11.7984C0.584412 12.1193 0.846912 12.3818 1.16774 12.3818H9.33441C9.65524 12.3818 9.91774 12.1193 9.91774 11.7984V11.2151C9.91774 9.66342 6.80858 8.88176 5.25108 8.88176ZM9.02524 4.66426C9.21191 5.12509 9.21191 5.63842 9.02524 6.09925C8.91441 6.37342 8.96108 6.68259 9.17108 6.89259L9.18858 6.91009C9.52691 7.24842 10.1044 7.17842 10.3261 6.75259C10.7694 5.90676 10.7694 4.91509 10.3144 4.03426C10.0927 3.60259 9.50941 3.52092 9.16524 3.86509L9.15941 3.87092C8.96108 4.07509 8.91441 4.39009 9.02524 4.66426ZM11.1894 1.81759C10.9561 2.05092 10.9211 2.41259 11.1136 2.68092C12.2627 4.27926 12.2569 6.42009 11.0961 8.07675C10.9094 8.33925 10.9502 8.70092 11.1777 8.92842L11.1952 8.94592C11.4811 9.23175 11.9652 9.20842 12.2102 8.88759C13.8144 6.82259 13.8202 4.00509 12.2102 1.87592C11.9652 1.55509 11.4752 1.53176 11.1894 1.81759Z' />
    </svg>,
);

export default RecordVoiceIcon;
