import { IRecord } from '../types/IRecord';
import { addDoc, collection, deleteDoc, doc, DocumentData, getDoc, updateDoc } from "firebase/firestore/lite";
import { db, storage } from '../firebase';
import { FirebaseCollection } from "../utils/firebase";
import { Lang } from '../config/lang';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';
import { requestAuthor } from "./author";

export const getRecordQueryKey = 'record';
export const getRecordsQueryKey = 'records';

export const hydrateRecord = (recId: string, data: DocumentData): IRecord => {
    const initData: IRecord = {
        id: '',
        createdAt: '',
        createdBy: '',
        lang: Lang.en,
        narratorAvatar: {
            src: '',
        },
        narratorVideoIntro: {
            src: '',
        },
        pages: [],
        smartObjectBook: '',
        smartObject: '',
        narratorUserId: null,
        full_transition_audio_intro_offset: 0,
        sampleAudio: {
            src: '',
        },
        sampleDurationMs: 0,
        narratorName: '',
        processing_status: '',
        recordingType: 'local',
    };

    const mapDocumentData = ({ lang, ...data }: DocumentData) => {
        return {
            ...data,
            lang: lang?.toLowerCase(),
        };
    };

    return {
        ...initData,
        ...mapDocumentData(data),
        id: recId,
    };
};

export const requestRecord = async (recordId?: string): Promise<IRecord | null> => {
    if (!recordId) return null;
    const ref = doc(db, FirebaseCollection.RECORD, recordId);
    const snapshot = await getDoc(ref);
    let data = snapshot.data();
    let contributor = null;

    if (data?.contributorID) {
        contributor = await requestAuthor(data.contributorID);
    }

    if (!data) {
        return null;
    }

    data.contributor = contributor;

    return hydrateRecord(ref.id, data);
};

export const requestRecords = async (ids: string[]): Promise<IRecord[]> => {
    if (ids.length < 1) {
        return [];
    }
    return await Promise.all(ids.map(requestRecord)).then(list =>
        list.reduce((list: IRecord[], record) => (record ? [...list, record] : list), []),
    );
};

export const requestDeleteRecord = async (recordId: string): Promise<void> => {
    await deleteDoc(doc(db, FirebaseCollection.RECORD, recordId));
};

export const requestDeleteRecordFolder = async (recordId: string): Promise<void> => {
    const desertRef = ref(storage, `${FirebaseCollection.RECORD}/${recordId}`);
    await listAll(desertRef).then(res => {
        res.items.forEach(async itemRef => {
            await deleteObject(itemRef);
        });
    });
};

export const requestCreateRecord = async (data: Partial<IRecord>) => {
    const docRef = await addDoc(collection(db, FirebaseCollection.RECORD), {
        ...data,
        createdAt: new Date().toISOString(),
    });
    return docRef.id;
};

export type ReqUploadRecordAvatar = {
    recordId: string;
    file: File;
};

export const requestUploadRecordAvatar = async ({ recordId, file }: ReqUploadRecordAvatar) => {
    const metadata = {
        contentType: 'image/jpeg',
    };
    const storageRef = ref(storage, `_recordings/${recordId}/narratorAvatar.jpeg`);
    const snap = await uploadBytes(storageRef, file, metadata);
    return await getDownloadURL(snap.ref);
};


export type ReqUpdateRecord = {
    recordId: string, payload: Partial<IRecord>;
}
export const requestUpdateRecord = async ({ recordId, payload }: ReqUpdateRecord): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.RECORD, recordId);
    await updateDoc(recordRef, payload);
};
