import React, { FC } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import FloatingLabel from "../FloatingLabel";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    error?: boolean;
    bgColor?: 'white';
    fieldSize?: 'medium' | 'large';
    label: string;
    required?: boolean;
}

const InputField: FC<IProps> = ({ className, error, bgColor, fieldSize, label, required, ...attributes }) => {
    return (
        <FloatingLabel labelText={label} value={attributes.value as any} size={fieldSize} bgColor={bgColor} required={required}>
        <input
            className={cn(styles.input, {
                [className || '']: className,
                [styles.error]: error,
                [styles[bgColor || '']]: bgColor,
                [styles[fieldSize || '']]: fieldSize,
            })}
            {...attributes}
        />
        </FloatingLabel>
    );
};

export default InputField;
