import {useState, useRef, useEffect, useCallback, useMemo} from 'react';

const useTimer = (initialState = 0) => {
    const [timer, setTimer] = useState<number>(initialState)
    const [isActive, setIsActive] = useState<boolean>(false)
    const [isPaused, setIsPaused] = useState<boolean>(false)
    const countRef = useRef<any>(null)

    const handleStart = useCallback(() => {
        setIsActive(true)
        setIsPaused(true)
        const startTime = new Date();
        countRef.current = setInterval(() => {
            const timeElapsed = new Date().getTime() - startTime.getTime();
            setTimer(timeElapsed)
        }, 25)
    }, [])

    const handlePause = useCallback(() => {
        clearInterval(countRef.current)
        setIsPaused(false)
    }, [])

    const handleResume = useCallback(() => {
        setIsPaused(true)
        const startTime = new Date();
        countRef.current = setInterval(() => {
            const timeElapsed = new Date().getTime() - startTime.getTime();
            setTimer(timeElapsed)
        }, 25)
    }, [])

    const handleReset = useCallback(() => {
        clearInterval(countRef.current)
        setIsActive(false)
        setIsPaused(false)
        setTimer(0)
    }, [])

    useEffect(() => {
        return () => {
            handleReset()
        }
    }, [handleReset])

    return useMemo(() => ({timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset}),
        [timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset])
}

export const formatTime = (milliseconds: number): string => {
    const seconds = Math.floor(milliseconds / 1000);
    const getSeconds = `0${(seconds % 60)}`.slice(-2);
    const minutes = Math.floor(seconds / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getMinutes}:${getSeconds}`
}

export default useTimer
