export enum TypographySize {
    'LX5' = 'LX5', // 96px
    'LX4' = 'LX4', // 60px
    'LX3' = 'LX3', // 48px
    'LX2' = 'LX2', // 34px
    'XL' = 'XL', // 24px
    'L' = 'L', // 20px
    'M' = 'M', // 16px
    'S' = 'S', // 14px
    'XS' = 'XS', // 12px
    'XXS' = 'XXS', // 10px
}

export enum TypographyTag {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    span = 'span',
}


export enum TypographyType {
    success = 'success',
    warning = 'warning',
    error = 'error',
}
