import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Mail from '../icons/Mail';
import Button from '../../../components/common/Button';
import Magic from '../icons/Magic';
import FloatingLabel from '../../../components/common/FloatingLabel';
import Input from '../../../components/common/Input';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../../../firebase';
import { toast } from 'react-toastify';
import { emailPattern } from '../../../utils/regExp';
import routes from '../../../config/routes';
import { LangContext } from '../../../context/lang';
import axios from 'axios';
import Header from '../../../components/common/Headers/Header';
import BackButton from '../../../components/common/Headers/components/BackButton';

const signInEmailKey = 'signInEmail';
const timeLeftValue = 60;
const AuthWithLink: FC = () => {
    const { t } = useTranslation('sign-up');
    const [email, setEmail] = useState<string>(window?.localStorage.getItem(signInEmailKey) || '');
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(timeLeftValue);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

    const { lang } = useContext(LangContext);

    const { search } = useLocation();

    useEffect(() => {
        let intervalId: any;

        if (isEmailSent || search) {
            if (timeLeft <= 0) {
                setTimeLeft(0);
                return;
            }
            intervalId = setTimeout(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
        }
        return () => clearTimeout(intervalId);
    }, [timeLeft, isEmailSent, search]);

    const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEmailValid(true);
        setEmail(e.target.value);
    }, []);

    const handleSignInWithEmailLink = useCallback(
        (email: string) => {
            const url = window.location.href;
            signInWithEmailLink(auth, email, url)
                .then(result => {
                    window.localStorage.removeItem(signInEmailKey);
                })
                .catch(error => {
                    toast.error(t('authorizationFailed'));
                    window?.console.error(error.message);
                });
        },
        [t],
    );

    useEffect(() => {
        const url = window.location.href;
        if (isSignInWithEmailLink(auth, url)) {
            let email = window.localStorage.getItem(signInEmailKey);
            if (!email) {
                email = window.prompt(t('enterEmailConfirmation'));
            }
            if (!email) {
                toast.error(t('noEmailProvidedFails'));
                return;
            }
            handleSignInWithEmailLink(email);
        }
    }, [handleSignInWithEmailLink, t]);

    useEffect(() => {
        return () => {
            window.localStorage.removeItem(signInEmailKey);
            setIsEmailSent(false);
        };
    }, []);

    const handleSendMagicLink = useCallback(async () => {
        if (!email || !emailPattern.test(email)) {
            setIsEmailValid(false);
            return;
        }
        try {
            setIsLoading(true);
            // await sendSignInLinkToEmail(auth, email, {
            //     url: window.location.href,
            //     handleCodeInApp: true,
            // });

            await axios.post('https://europe-west1-iks-book.cloudfunctions.net/sendSignInLink', {
                email,
                lang,
                continueUrl: window.location.href,
            });
            window.localStorage.setItem(signInEmailKey, email);
            setIsEmailSent(true);
        } catch (e) {
            toast.error(t('sendAuthLinkFailed'));
            window?.console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [email, t, lang]);

    const handleResendPress = useCallback(async () => {
        await handleSendMagicLink();
        setTimeLeft(timeLeftValue);
    }, [handleSendMagicLink]);
    return (
        <section className={styles.root}>
            <Header primary leftSlot={<BackButton backRoute={routes.accountSignUp} />}>
                {t('authorization')}
            </Header>
            <div className={styles.content}>
                {isEmailSent || search ? (
                    <>
                        <div className={styles.info}>
                            <Mail />
                            <h1>{t('checkYourEmail')}</h1>
                            <p>
                                {t('weSentEmailTo')} <span>{email}</span>. {t('checkYourInbox')}
                            </p>
                        </div>
                        {timeLeft > 0 && (
                            <div className={styles.time}>
                                {t('waitFor')} <span>{timeLeft}</span> {t('waitForP2')}
                            </div>
                        )}
                        <Button
                            size='large'
                            textUpperCase
                            onClick={handleResendPress}
                            isLoading={isLoading}
                            ghost={timeLeft > 0}
                            disabled={timeLeft > 0}
                        >
                            {t('resendMagicLink')}
                        </Button>
                        {/*<br />*/}
                        {/*<Button*/}
                        {/*    size='large'*/}
                        {/*    textUpperCase*/}
                        {/*    ghost*/}
                        {/*    blue*/}
                        {/*    onClick={() => {*/}
                        {/*        window?.location?.reload();*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {t('reloadPage')}*/}
                        {/*</Button>*/}
                    </>
                ) : (
                    <>
                        <div className={styles.info}>
                            <Magic />
                            <h1>{t('authMagicLink')}</h1>
                            <p>{t('enterEmailTxt')}</p>
                        </div>
                        <div className={styles.mailField}>
                            <FloatingLabel labelText={t('yourEmail')} value={email}>
                                <Input value={email} type='email' onChange={handleEmailChange} disabled={isLoading} error={!isEmailValid} />
                            </FloatingLabel>
                            {!isEmailValid && <div className={styles.error}>{t('enterValidEmail')}</div>}
                        </div>
                        <Button size='large' textUpperCase onClick={handleSendMagicLink} isLoading={isLoading}>
                            {t('sendMagicLink')}
                        </Button>
                    </>
                )}
            </div>
        </section>
    );
};

export default AuthWithLink;
