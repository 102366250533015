import React, { FC } from 'react';
import styles from './styles.module.scss';
import AudioProgressBar from '../AudioProgressBar';
import LoadingIcon from '../../../../icons/Loading';
import PauseIcon from '../../../../icons/Pause';
import PlayIcon from '../../../../icons/Play';
import CloseIcon from '../../../../icons/Close';
import { useSwipeable } from '../../../../../utils/Swipe/Swipe';
import { ImageSizes } from '../../../../../utils/getSizedImage';

type Props = {
    isPlaying: boolean;
    isAudioLoading: boolean;
    title: string;
    author: string | null | undefined;
    chapterName: string | null | undefined;
    sizedCover: ImageSizes | null;
    fullDuration: number;
    audioRef: React.MutableRefObject<HTMLAudioElement | null>;
    selectedAudioTrack: string | undefined;
    onMiniPlayerClose?: () => void;
    toggleMiniPlayerMode?: () => void;
    handlePausePress: () => void;
    handlePlayPress: () => void;
};
const MiniPlayer: FC<Props> = ({
    title,
    isAudioLoading,
    isPlaying,
    author,
    sizedCover,
    audioRef,
    chapterName,
    fullDuration,
    selectedAudioTrack,
    onMiniPlayerClose,
    toggleMiniPlayerMode,
    handlePausePress,
    handlePlayPress,
}) => {
    const myRef = React.useRef<HTMLDivElement>();
    const refPassthrough = (el: any) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);

        // set myRef el so you can access it yourself
        myRef.current = el;
    };

    const handlers = useSwipeable({
        onSwiping: data => {
            if (myRef.current) {
                myRef.current.style.transform = `translateX(${data.deltaX}px)`;
            }
        },
        onSwiped: data => {
            if (myRef.current) {
                const minToShowBtn = 70;
                const elWidth = myRef.current.offsetWidth;
                const directionNum = data.dir.toLowerCase() === 'left' ? -1 : 1;

                if (data.absX >= minToShowBtn && data.absX <= elWidth / 1.5) {
                    myRef.current.style.transform = `translateX(${directionNum * minToShowBtn}px)`;
                }

                if (data.absX <= minToShowBtn) {
                    myRef.current.style.transform = `translateX(0)`;
                }

                if (data.absX >= elWidth / 1.5) {
                    onMiniPlayerClose?.();
                }
            }
        },
    });

    return (
        <div className={styles.miniPlayerBox}>
            <div className={styles.miniPlayer} {...handlers} ref={refPassthrough}>
                <picture onClick={toggleMiniPlayerMode}>
                    {sizedCover?.webp && <source srcSet={sizedCover.webp} type='image/webp' />}
                    <img src={sizedCover?.jpg || sizedCover?.original} alt='' loading='lazy' />
                </picture>
                <div className={styles.miniInfo}>
                    <div className={styles.miniTitle} onClick={toggleMiniPlayerMode}>
                        {title}
                    </div>
                    {author && <div className={styles.miniAuthor}>{author}</div>}
                    <AudioProgressBar
                        audioTitle={chapterName || ''}
                        audioEl={audioRef.current}
                        totalTime={fullDuration}
                        isMini
                        updateAudioEvents={selectedAudioTrack} // need to re-render component when audio track changes to have correct progress bar and timings values set
                    />
                </div>
                <div className={styles.miniBtnBox}>
                    <button onClick={isPlaying ? handlePausePress : handlePlayPress} disabled={isAudioLoading}>
                        {isAudioLoading ? (
                            <LoadingIcon color='white' size={32} />
                        ) : isPlaying ? (
                            <PauseIcon color='white' size={40} />
                        ) : (
                            <PlayIcon color='white' size={40} />
                        )}
                    </button>
                </div>
            </div>
            <div className={styles.closeButtons}>
                <button type='button' onClick={onMiniPlayerClose}>
                    <CloseIcon color='white' size={30} />
                </button>
                <button type='button' onClick={onMiniPlayerClose}>
                    <CloseIcon color='white' size={30} />
                </button>
            </div>
        </div>
    );
};

export default MiniPlayer;
