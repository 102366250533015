import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { formatTime } from 'hooks/useTimer';

import styles from './styles.module.scss';
import { ICustomMap } from 'types/ICustomMap';
import cn from "classnames";
// import { Howl } from 'howler';

type IProps = {
    totalTime: number; // milliseconds
    audioEl?: HTMLAudioElement | null;
    isMini?: boolean;
    audioTitle?: string;
    updateAudioEvents?: string | number; // need to re-render component when audio track changes to have correct progress bar and timings values set
};

const AudioProgressBar: FC<IProps> = ({ totalTime, audioEl, audioTitle, updateAudioEvents, isMini }) => {
    const [playingTime, setPlayingTime] = useState<number>(0); // seconds

    const onPlaying = useCallback((evt: ICustomMap) => {
        // setPlayingTime(Math.floor(evt.detail.seek));
        setPlayingTime(Math.floor(evt.target.currentTime));
    }, []);

    useEffect(() => {
        // document.addEventListener('audio-time-update', onPlaying);
        audioEl?.addEventListener('timeupdate', onPlaying);
        return () => {
            // document.removeEventListener('audio-time-update', onPlaying);
            audioEl?.removeEventListener('timeupdate', onPlaying);
        };
    }, [onPlaying, audioEl, updateAudioEvents]);

    const onTrackChange = useCallback(
        (evt: ICustomMap) => {
            const value = parseFloat(evt.target.value);
            const seconds = (totalTime / 1000 / 100) * value;
            // sound.seek(seconds);
            if (audioEl) audioEl.currentTime = seconds;
        },
        [audioEl, totalTime],
    );

    const progress = (100 * 1000 * playingTime) / totalTime;

    return (
        <div className={styles.progressBarWrap}>
            <div className={cn(styles.track, {[styles.isMini]: isMini})}>
                <input
                    value={progress}
                    className={styles.trackBar}
                    onChange={onTrackChange}
                    style={{ backgroundSize: `${progress}% 100%` }}
                    type='range'
                    min='0'
                    max='100'
                    step='.1'
                />
            </div>
            <div className={cn(styles.timings, {[styles.isMini]: isMini})}>
                <span>{formatTime(playingTime * 1000)}</span>
                <span className={styles.audioTitle}>{audioTitle}</span>
                <span>{formatTime(totalTime)}</span>
            </div>
        </div>
    );
};

export default memo(AudioProgressBar);
