import { isBeta, isDev, isProd } from "./isProd";
import { datadogRum } from '@datadog/browser-rum';
const prodAppId = '8f076690-8198-4b04-8cf1-ea5fcd6c42ad';
const devAppId = 'fb23a5c0-46c0-44bd-ab01-181e8d0cd2e8';
const betaAppId = 'e0c6f4d1-565e-4e0f-beb6-aad95cfd7d5b';
const prodClientToken = 'pub865c3b9a9a84b2b90c6749cc70672a72';
const devClientToken = 'pub030444b871ad1d316662b2854bff8ee6';
const betaClientToken = 'pub0718e0018ccf499252978f23abf21453';

const prodService = 'web-react-play-bts-prod';
const devService = 'web-react-play-bts-dev';
const betaService = 'web-react-play-bts-beta';

const prodEnv = 'prod';
const devEnv = 'dev';
const betaEnv = 'beta';

export const initDatalog = () => {
    if (isProd || isDev || isBeta) {
        let appId = isProd ? prodAppId : devAppId;
        appId = isBeta ? betaAppId : appId;

        let clientToken = isProd ? prodClientToken : devClientToken;
        clientToken = isBeta ? betaClientToken : clientToken;

        let service = isProd ? prodService : devService;
        service = isBeta ? betaService : service;

        let env = isProd ? prodEnv : devEnv;
        env = isBeta ? betaEnv : env;

        datadogRum.init({
            applicationId: appId,
            clientToken: clientToken,
            site: 'datadoghq.eu',
            service: service,
            env,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
        });
    }
}
