// get CDN urls from tockened firestorage files

// example input: https://firebasestorage.googleapis.com/v0/b/iks-book.appspot.com/o/_recordings%2F0i3XiKtjnBjLYpDNCDJM%2Ffull_audio?alt=media&token=eb2d8014-e404-4bdd-a6b2-dfac25e33be0
// example output: https://cdn.bettertimestories.com/_recordings/0i3XiKtjnBjLYpDNCDJM/full_audio
export const getCDNUrl = (dbURL?: string): string | undefined => {
    if (!dbURL) return undefined;
    //get public url if needed
    let publicUrl = '';
    if (dbURL.includes('firebasestorage.googleapis.com')) {
        // Get path in bucket
        const pathToFile = dbURL.replace('https://firebasestorage.googleapis.com/v0/b/iks-book.appspot.com/o/', '');
        publicUrl = decodeURIComponent(pathToFile.split('?')[0]);
    } else {
        publicUrl = dbURL;
    }
    //remove domain
    let path = publicUrl.replace('https://storage.googleapis.com/iks-book.appspot.com/', '');
    path = path.replace('https://storage.googleapis.com/download/storage/v1/b/iks-book.appspot.com/o/', '');

    //public URL
    return `https://cdn.bettertimestories.com/${path}`;
};
