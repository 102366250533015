import {useMutation, useQueryClient} from 'react-query';
import { convertSmartObject, createSmartObject, updateUser } from '../utils/firebase';
import { IUser, IUserBook } from '../types/IUser';
import { IQrCode } from '../types/IQrCode';
import { useRemoveQrCode } from './useRemoveQrCode';
import {QueryKey} from "../queries";
import {useContext} from "react";
import {AuthContext} from "../context/auth";

export const USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED';
export const BOOK_NOT_FOUND = 'BOOK_NOT_FOUND';
export const BOOK_ALREADY_ADDED = 'BOOK_ALREADY_ADDED';

export type UseAddBookToUserLibraryPayload = {
    qrCode?: IQrCode | null;
};

const updateUserLibrary = (user: IUser, userBook: IUserBook): Promise<void> => {
    return updateUser(user.id, {
        library: [userBook],
    });
};

const addBookToUserLibrary =
    (user?: IUser) =>
    async ({ qrCode }: UseAddBookToUserLibraryPayload): Promise<void> => {
        if (!user) {
            throw new Error(USER_NOT_AUTHORIZED);
        }

        const smartBookId = qrCode?.bookId;

        if (!smartBookId) {
            throw new Error(BOOK_NOT_FOUND);
        }

        if (user.library.find(userBook => userBook.smartBookId === smartBookId)) {
            throw new Error(BOOK_ALREADY_ADDED);
        }

        if (qrCode?.objectId) {
            await convertSmartObject(user.id, qrCode.objectId);
            return updateUserLibrary(user, {
                smartBookId,
                smartObjectId: qrCode.objectId,
            });
        }

        const smartObjectId = await createSmartObject(user.id, smartBookId);
        return updateUserLibrary(user, {
            smartBookId,
            smartObjectId,
        });
    };

export const useAddBookToUserLibrary = () => {
    const { user } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const { mutateAsync: removeCachedQrCodeData } = useRemoveQrCode();

    return useMutation<void, unknown, UseAddBookToUserLibraryPayload>({
        mutationFn: addBookToUserLibrary(user || undefined),
        onSuccess: () => {
            removeCachedQrCodeData();
            queryClient.invalidateQueries(QueryKey.GET_USER);
        },
    });
};
