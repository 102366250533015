import { IQrCode, IQrCodeAccessType } from '../types/IQrCode';
import { getSmartObject, getSmartBook } from './firebase';

const isPermittedAccessType = (target: unknown): target is IQrCodeAccessType => {
    return typeof target === 'string' && ['1', '2', '3'].includes(target);
};

type AccessType = {
    secret?: 'qrSecret' | 'nfcSecret' | 'remoteRecSecret';
    isBanned?: 'qrIsBanned' | 'nfcIsBanned';
};

abstract class QrCode {
    protected constructor(protected props: IQrCode) {}

    protected mapAccessType(): AccessType {
        const { accessType } = this.props;

        if (accessType === '1')
            return {
                secret: 'qrSecret',
                isBanned: 'qrIsBanned',
            };
        if (accessType === '2')
            return {
                secret: 'nfcSecret',
                isBanned: 'nfcIsBanned',
            };
        if (accessType === '3')
            return {
                secret: 'remoteRecSecret',
            };

        return {};
    }

    protected abstract validate(): Promise<QrCode>;

    value(): IQrCode {
        return {
            accessType: this.props.accessType,
            objectId: this.props.objectId,
            bookId: this.props.bookId,
            secret: this.props.secret,
        };
    }
}

export class SmartObjectQrCode extends QrCode {
    protected async validate(): Promise<QrCode> {
        const smartObject = await getSmartObject(this.props.bookId);

        if (!smartObject) {
            throw new Error('Object not found');
        }

        const keys = this.mapAccessType();

        if (keys.isBanned && smartObject[keys.isBanned]) {
            throw new Error('Object banned');
        }
        if (keys.secret && smartObject[keys.secret] !== this.props.secret) {
            throw new Error('Corrupted QRCode');
        }
        if (smartObject.isDeleted) {
            throw new Error('Object not found');
        }

        return new SmartObjectQrCode({
            accessType: this.props.accessType,
            bookId: smartObject.smartObjectBook,
            objectId: smartObject.id,
            secret: this.props.secret,
        });
    }

    static create(key: string): Promise<QrCode> {
        const [accessType, bookId, secret] = key.split('-');

        if (!isPermittedAccessType(accessType) || !bookId || !secret) {
            throw new Error('Forbidden hash code');
        }

        return new SmartObjectQrCode({ accessType, bookId, secret }).validate();
    }
}

export class SmartBookQrCode extends QrCode {
    protected async validate(): Promise<QrCode> {
        const smartBook = await getSmartBook(this.props.bookId);
        if (!smartBook) {
            throw new Error('Book not found');
        }
        return this;
    }

    static create(key: string): Promise<QrCode> {
        const [accessType, bookId, secret] = key.split('-');

        if (!isPermittedAccessType(accessType) || !bookId || !secret) {
            throw new Error('Forbidden hash code');
        }

        return new SmartBookQrCode({ accessType, bookId, secret }).validate();
    }
}
