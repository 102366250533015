import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File) =>
    new Promise<File>((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri as File);
            },
            "file"
        );
    });
