import { BrowserStorage, StorageKey } from '..';
import { IQrCode } from '../../types/IQrCode';

type QrCodeStorageValue = IQrCode | null;

export class QrCodeStorage extends BrowserStorage<QrCodeStorageValue> {
    constructor() {
        super(StorageKey.QR_CODE, null);
    }

    get() {
        return this.getStorage();
    }

    set(qrCode: QrCodeStorageValue) {
        return this.setStorage(qrCode);
    }

    remove() {
        this.removeStorage();
    }
}

const qrCodeStorage = new QrCodeStorage()
export default qrCodeStorage;
