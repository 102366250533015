import React, { FC, ReactNode } from 'react';
import { ICustomMap } from '../../../types/ICustomMap';
import { TypographySize, TypographyTag, TypographyType } from './types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Tag = ({ tagName, children, ...props }: ICustomMap) => React.createElement(tagName, props, children);

type Props = {
    tagName?: TypographyTag;
    size?: TypographySize;
    type?: TypographyType;
    uppercase?: boolean;
    children: ReactNode;
    className?: string;
    bold?: boolean;
};
const Typography: FC<Props> = ({ tagName = TypographyTag.p, children, size = TypographySize.M, className, type, bold, uppercase }) => {
    return (
        <Tag
            tagName={tagName}
            className={cn({
                [className || '']: className,
                [styles[size]]: size,
                [styles[type || '']]: type,
                [styles.uppercase]: uppercase,
                [styles.bold]: bold,
            })}
        >
            {children}
        </Tag>
    );
};

export default Typography;
