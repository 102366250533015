import React, { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import routes from '../../../../../config/routes';
import HomeIcon from '../../../../icons/Home';
import ArrowLeft from '../../../../icons/ArrowLeft';
import { useNavigate } from 'react-router';

type Props = {
    backRoute?: string;
    onBackPress?: () => void;
    className?: string;
};
const BackButton: FC<Props> = ({ backRoute, onBackPress, className }) => {
    const showHomeButton = window?.history?.state?.idx === 0; // no back route
    const navigate = useNavigate();
    const handleBackPress = () => {
        if (onBackPress) {
            onBackPress();
            return;
        }
        if (window.history?.state?.idx === 0) {
            navigate(routes.home);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className={cn(styles.backBtnBox, { [className || '']: className })}>
            {showHomeButton && !backRoute ? (
                <Link to={routes.home}>
                    <HomeIcon />
                </Link>
            ) : (
                <>
                    {backRoute && (
                        <Link to={backRoute} onClick={onBackPress}>
                            <ArrowLeft />
                        </Link>
                    )}
                    {!backRoute && (
                        <button onClick={handleBackPress}>
                            <ArrowLeft />
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default BackButton;
