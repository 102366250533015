import { SvgFactory } from '../../Svg';

const CloseIcon = SvgFactory(
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_831_43848)'>
            <path d='M18 6L6 18' stroke='#00446B' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M6 6L18 18' stroke='#00446B' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
            <clipPath id='clip0_831_43848'>
                <rect width='24' height='24' fill='#00446B' />
            </clipPath>
        </defs>
    </svg>,
);

export default CloseIcon;
